import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { float_tg } from "../../images";
import styles from "./style.module.scss";

const FloatButton = ({ show, onClick }: any) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.floatButton} ${!show && styles.disable}`}>
      <div className={styles.top}>
        <Link
          activeClass="active"
          to="part-5"
          containerId="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={300}
        >
          <Image
            src={float_tg}
            alt="float_tg"
            preview={false}
            className={styles.img}
          />
        </Link>
        <div
          className={styles.name}
          dangerouslySetInnerHTML={{ __html: t("home.float.name") }}
        ></div>
      </div>
      <div className={styles.goTop} onClick={onClick}>
        <div className={styles.text}>{t("home.float.goTop")}</div>
      </div>
    </div>
  );
};

export default FloatButton;
