import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import FloatButton from "./components/FloatButton";
import {
  card_1,
  card_2,
  card_3,
  card_4,
  card_5,
  card_6,
  card_7,
  card_8,
  card_9,
  footer_facebook,
  footer_instagram,
  footer_linkedin,
  footer_logo,
  footer_skype,
  footer_tg,
  footer_twitter,
  last_money,
  last_people,
  last_qrcode,
  last_tg,
  partner_77,
  partner_cq9,
  partner_evo,
  partner_evoslot,
  partner_fc,
  partner_gamewin,
  partner_jdb,
  partner_jili,
  partner_luckysport,
  partner_mg,
  partner_pg,
  partner_pp,
  partner_ps,
  partner_saba,
  partner_sexy,
  partner_spribe,
  partner_tg,
  partner_viacasino,
  partner_we,
  partner_yb,
  top_bull,
  top_girl,
  top_god,
  top_pig,
  top_roulette,
  top_shark,
  top_soccer,
  top_tb,
  top_tiger,
  version_1,
  version_10,
  version_11,
  version_12,
  version_13,
  version_14,
  version_15,
  version_16,
  version_17,
  version_18,
  version_19,
  version_2,
  version_20,
  version_21,
  version_22,
  version_23,
  version_24,
  version_25,
  version_26,
  version_27,
  version_28,
  version_29,
  version_3,
  version_30,
  version_31,
  version_32,
  version_33,
  version_4,
  version_5,
  version_6,
  version_7,
  version_8,
  version_9,
} from "./images/index";
import styles from "./style.module.scss";
import "./style.scss";

const Home = () => {
  const { t } = useTranslation();
  const homeRef = useRef<any>(null);
  const topRef = useRef<any>(null);
  const lastRef = useRef<any>(null);
  const footerRef = useRef<any>(null);
  const sliderRef = useRef<any>(null);
  const [showFloatButton, setShowFloatButton] = useState<boolean>(false);
  const [topVisible, setTopVisible] = useState<boolean>(false);
  const [lastVisible, setLastVisible] = useState<boolean>(false);

  const topEnum: any = [
    { key: 1, src: top_soccer, name: "top_soccer", className: styles.soccer },
    {
      key: 2,
      src: top_roulette,
      name: "top_roulette",
      className: styles.roulette,
    },
    { key: 3, src: top_shark, name: "top_shark", className: styles.shark },
    { key: 4, src: top_girl, name: "top_girl", className: styles.girl },
    { key: 5, src: top_bull, name: "top_bull", className: styles.bull },
    { key: 6, src: top_tiger, name: "top_tiger", className: styles.tiger },
    { key: 7, src: top_pig, name: "top_pig", className: styles.pig },
    { key: 8, src: top_god, name: "top_god", className: styles.god },
    { key: 9, src: top_tb, name: "top_tb", className: styles.tb },
  ];

  const advantageEnum: any = [
    { key: 1, src: card_1, name: "card_1" },
    { key: 2, src: card_2, name: "card_2" },
    { key: 3, src: card_3, name: "card_3" },
    { key: 4, src: card_4, name: "card_4" },
    { key: 5, src: card_5, name: "card_5" },
    { key: 6, src: card_6, name: "card_6" },
    { key: 7, src: card_7, name: "card_7" },
    { key: 8, src: card_8, name: "card_8" },
    { key: 9, src: card_9, name: "card_9" },
  ];

  const partnerEnum: any = [
    { key: 1, src: partner_77, name: "partner_77" },
    { key: 2, src: partner_cq9, name: "partner_cq9" },
    { key: 3, src: partner_evo, name: "partner_evo" },
    { key: 4, src: partner_evoslot, name: "partner_evoslot" },
    { key: 5, src: partner_fc, name: "partner_fc" },
    // { key: 6, src: partner_galaxsys, name: "partner_galaxsys" },
    { key: 7, src: partner_gamewin, name: "partner_gamewin" },
    { key: 8, src: partner_jdb, name: "partner_jdb" },
    { key: 9, src: partner_jili, name: "partner_jili" },
    { key: 10, src: partner_luckysport, name: "partner_luckysport" },
    { key: 11, src: partner_mg, name: "partner_mg" },
    { key: 12, src: partner_pg, name: "partner_pg" },
    { key: 13, src: partner_pp, name: "partner_pp" },
    { key: 14, src: partner_ps, name: "partner_ps" },
    // { key: 15, src: partner_pt, name: "partner_pt" },
    { key: 16, src: partner_saba, name: "partner_saba" },
    { key: 17, src: partner_sexy, name: "partner_sexy" },
    // { key: 18, src: partner_slotmill, name: "partner_slotmill" },
    { key: 19, src: partner_spribe, name: "partner_spribe" },
    // { key: 20, src: partner_spribeone, name: "partner_spribeone" },
    { key: 21, src: partner_tg, name: "partner_tg" },
    { key: 22, src: partner_viacasino, name: "partner_viacasino" },
    { key: 23, src: partner_we, name: "partner_we" },
    { key: 24, src: partner_yb, name: "partner_yb" },
    // { key: 25, src: partner_ygg, name: "partner_ygg" },
    // { key: 26, src: partner_youbet, name: "partner_youbet" },
  ];

  const versionEnum: any = [
    { key: 1, src: version_1, name: "version_1" },
    { key: 2, src: version_2, name: "version_2" },
    { key: 3, src: version_3, name: "version_3" },
    { key: 4, src: version_4, name: "version_4" },
    { key: 5, src: version_5, name: "version_5" },
    { key: 6, src: version_6, name: "version_6" },
    { key: 7, src: version_7, name: "version_7" },
    { key: 8, src: version_8, name: "version_8" },
    { key: 9, src: version_9, name: "version_9" },
    { key: 10, src: version_10, name: "version_10" },
    { key: 11, src: version_11, name: "version_11" },
    { key: 12, src: version_12, name: "version_12" },
    { key: 13, src: version_13, name: "version_13" },
    { key: 14, src: version_14, name: "version_14" },
    { key: 15, src: version_15, name: "version_15" },
    { key: 16, src: version_16, name: "version_16" },
    { key: 17, src: version_17, name: "version_17" },
    { key: 18, src: version_18, name: "version_18" },
    { key: 19, src: version_19, name: "version_19" },
    { key: 20, src: version_20, name: "version_20" },
    { key: 21, src: version_21, name: "version_21" },
    { key: 22, src: version_22, name: "version_22" },
    { key: 23, src: version_23, name: "version_23" },
    { key: 24, src: version_24, name: "version_24" },
    { key: 25, src: version_25, name: "version_25" },
    { key: 26, src: version_26, name: "version_26" },
    { key: 27, src: version_27, name: "version_27" },
    { key: 28, src: version_28, name: "version_28" },
    { key: 29, src: version_29, name: "version_29" },
    { key: 30, src: version_30, name: "version_30" },
    { key: 31, src: version_31, name: "version_31" },
    { key: 32, src: version_32, name: "version_32" },
    { key: 33, src: version_33, name: "version_33" },
  ];

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: any) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  const handleClickGoTop = () => {
    homeRef.current.style.scrollBehavior = "smooth";
    homeRef.current.scrollTop = 0;
    homeRef.current.style.scrollBehavior = "unset";
  };

  const scrollProcess = () => {
    // console.log(
    //   homeRef.current.scrollTop,  //目前網頁TOP滾動高度
    //   homeRef.current.scrollHeight,  //home總高度
    //   lastRef.current.clientHeight,  //lastContainer總高度
    //   window.document.documentElement.scrollHeight  //網頁可視總高度
    // );
    if (homeRef.current.scrollTop > topRef.current.clientHeight / 2) {
      setTopVisible(false);
    } else {
      setTopVisible(true);
    }
    if (
      homeRef.current.scrollTop + window.document.documentElement.scrollHeight >
      homeRef.current.scrollHeight -
        (lastRef.current.clientHeight / 2 + footerRef.current.clientHeight)
    ) {
      setLastVisible(true);
    } else {
      setLastVisible(false);
    }
    if (homeRef.current.scrollTop >= 100) {
      setShowFloatButton(true);
    } else {
      setShowFloatButton(false);
    }
    Aos.refresh();
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollProcess, true);
    return () => {
      window.removeEventListener("scroll", scrollProcess, true);
    };
  }, []);

  useEffect(() => {
    if (homeRef.current.scrollTop > topRef.current.clientHeight) {
      setTopVisible(false);
    } else {
      setTimeout(() => {
        setTopVisible(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <div className={`${styles.home} home`} id="home" ref={homeRef}>
      <div className={styles.topContainer} id="part-1" ref={topRef}>
        <div className={`${styles.leftText} ${!topVisible && styles.disabled}`}>
          2024 © TB Network Company.
        </div>
        <div className={`${styles.content} ${!topVisible && styles.disabled}`}>
          <div className={styles.title}>{t("normal.logoName")}</div>
          <div className={styles.desc}>{t("home.top.desc")}</div>
        </div>
        {topEnum.map((item: any) => {
          return (
            <div
              className={`${item.className} ${!topVisible && styles.disabled}`}
            >
              <Image src={item.src} alt={item.name} preview={false} />
            </div>
          );
        })}
      </div>
      <div className={styles.normalContainer} id="part-2">
        <div className={styles.title}>{t("home.advantage.title")}</div>
        <div className={styles.subTitle}>{t("home.advantage.subTitle")}</div>
        <div className={styles.content}>
          {advantageEnum.map((item: any) => {
            return (
              <div className={styles.card} key={item.key}>
                <div className={styles.cardTitle}>
                  <Image
                    src={item.src}
                    alt={item.name}
                    preview={false}
                    width={68}
                  />
                  <div className={styles.text}>
                    {t(`home.advantage.card.title${item.key}`)}
                  </div>
                </div>
                <div className={styles.cardContent}>
                  <div className={styles.contentTitle}>
                    {t(`home.advantage.card.title${item.key}`)}
                  </div>
                  {t(`home.advantage.card.desc${item.key}`)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`${styles.normalContainer} ${styles.partnerContainer}`}
        id="part-3"
      >
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{t("home.partner.title")}</div>
            <div className={styles.subTitle}>{t("home.partner.subTitle")}</div>
            <div className={styles.desc}>{t("home.partner.desc")}</div>
          </div>
          <div className={styles.content}>
            {partnerEnum.map((item: any) => {
              return (
                <div className={styles.img} key={item.key}>
                  <Image
                    src={item.src}
                    alt={item.name}
                    preview={false}
                    width={110}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`${styles.normalContainer} ${styles.versionContainer}`}
        id="part-4"
      >
        <div className={styles.container}>
          <div className={styles.title}>{t("home.version.title")}</div>
          <div className={styles.subTitle}>{t("home.version.subTitle")}</div>
          <div className={styles.desc}>{t("home.version.desc")}</div>
          <div className={styles.content}>
            <Swiper
              ref={sliderRef}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              loop={true}
              coverflowEffect={{
                rotate: 30,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={false}
              pagination={pagination}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="custom-swiper"
            >
              {versionEnum.map((item: any) => {
                return (
                  <SwiperSlide key={item.key}>
                    <Image
                      preview={false}
                      alt={item.name}
                      src={item.src}
                      style={{ borderRadius: "10px" }}
                      width={255}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div
              className={`${styles.swiperNavigate} ${styles.swiperPrev}`}
              onClick={handlePrev}
            >
              <LeftOutlined className={styles.icon} />
            </div>
            <div
              className={`${styles.swiperNavigate} ${styles.swiperNext} `}
              onClick={handleNext}
            >
              <RightOutlined className={styles.icon} />
            </div>
          </div>
        </div>
      </div>
      <Element className={styles.lastContainer} id="part-5" name="part-5">
        <div className={styles.content} ref={lastRef}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: t("home.last.title") }}
          />
          <div className={styles.qrCodeContainer}>
            <Image
              src={last_qrcode}
              alt="last_qrcode"
              preview={false}
              className={styles.qrCode}
            />
            <div className={styles.label}>
              <Image src={last_tg} alt="last_tg" preview={false} width={24} />
              <div className={styles.name}>tb_Service 0080</div>
            </div>
          </div>
          <div className={styles.desc}>{t("home.last.desc")}</div>
        </div>
        <div className={styles.money}>
          <Image src={last_money} alt="last_money" preview={false} />
        </div>
        <div className={styles.people}>
          <Image src={last_people} alt="last_people" preview={false} />
        </div>
      </Element>
      <div className={styles.footer} ref={footerRef}>
        <div className={styles.center}>
          <Image
            src={footer_logo}
            alt="footer_logo"
            preview={false}
            width={150}
          />
          <div className={styles.info}>
            <div className={styles.items}>
              <Image
                src={footer_tg}
                alt="footer_tg"
                preview={false}
                width={24}
              />
              <div className={styles.data}>tb_Service 0080</div>
            </div>
            <div className={styles.items}>
              <Image
                src={footer_skype}
                alt="footer_skype"
                preview={false}
                width={24}
              />
              <div className={styles.data}>tb_Service 0080@gmail.com</div>
            </div>
          </div>
          <div className={styles.footerText}>2024 © TB Network Company.</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.link}>
            <Image
              src={footer_facebook}
              alt="footer_logo"
              preview={false}
              width={7}
            />
            <Image
              src={footer_twitter}
              alt="footer_twitter"
              preview={false}
              width={18}
            />
            <Image
              src={footer_linkedin}
              alt="footer_linkedin"
              preview={false}
              width={16}
            />
            <Image
              src={footer_instagram}
              alt="footer_instagram"
              preview={false}
              width={16}
            />
          </div>
        </div>
      </div>
      <FloatButton show={showFloatButton} onClick={handleClickGoTop} />
    </div>
  );
};

export default Home;
